@import '../../_typography';
@import '../../_variables';

$topbarHeightSmall: 48px;
$topbarHeightMobile: 54px;
$topbarHeightTablet: 67px;
$topbarHeightDesktop: 54px;

.About {
  position: relative;
  z-index: $zAbout;
  background: var(--ui-color);
  min-height: 100%;

  .CloseButton {
    position: fixed;
    top: 5px;
    right: 5px;
    padding-bottom: 20px;

    svg {
      width: initial;
    }

    path {
      stroke: black;
    }
  }

  .AboutTop {
    box-sizing: border-box;
    position: fixed;
    transform: translateZ(0);
    perspective: 1000;
    top: 0;
    width: 100%;
    z-index: $zAbout + 1;
    align-items: center;
    height: $topbarHeightMobile;
    border-bottom: 2px solid black;
    display: flex;
    padding-left: 18px;
    background: var(--ui-color);

    .Button:not(.SvgButton) {
      @extend .language-type;

      &.selected {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          right: 2px;
          left: 2px;
          height: 2px;
          background: black;
          bottom: -2px;
        }
      }
    }

    .languages {
      button + button {
        margin-left: 21px;
      }
    }

    .mute {
      margin-left: 2.9em;

      .Button {
        pointer-events: all;
      }
    }

    .MuteButton {
      position: relative;
      padding-right: 9px;
      svg {
        height: 18px;
      }
    }
  }

  .AboutContent {
    @extend .about-type-small;
    padding: ($topbarHeightMobile + 5) 18px 2em;
    height: 100%;
    box-sizing: border-box;

    &.has-bar {
      padding-bottom: 6em;
    }

    .mobile.landscape & {
      padding-bottom: 4em;
    }

    .content-big {
      @extend .about-type-big;
      margin-bottom: 2.5em;
      padding: 0 10px;

      p {
        margin-top: 0.9em;
      }
    }

    section {
      p + p {
        margin-top: 0.5em;
      }
    }

    section + section,
    .column + .column {
      margin-top: 2em;
    }
  }

  @include small() {
    .CloseButton {
      top: 0;
      padding: 13px 8px 20px 20px;

      svg {
        height: 14px;
        width: auto;
      }
    }

    .AboutTop {
      padding-left: 14px;
      height: $topbarHeightSmall;

      .mute {
        margin-left: 2em;
      }

      .MuteButton {
        padding-right: 7px;
        svg {
          height: 15px;
          width: auto;
        }
      }

      .languages {
        button + button {
          margin-left: 18px;
        }
      }

      .Button:not(.SvgButton) {
        &.selected {
          &:after {
            bottom: 0;
          }
        }
      }
    }

    .AboutContent {
      padding: ($topbarHeightSmall) 18px 4em;

      &.has-bar {
        padding-bottom: 6em;
      }
    }
  }

  @include tablet() {
    .CloseButton {
      top: 10px;
      right: 11px;
    }

    .AboutTop {
      height: $topbarHeightTablet;
      padding-left: 21px;

      .languages {
        button + button {
          margin-left: 31px;
        }
      }

      .mute {
        margin-left: 4.1em;
      }

      .MuteButton {
        padding-right: 6px;

        svg {
          height: 20px;
        }
      }
    }

    .AboutContent {
      max-width: 560px;
      margin: 0 auto;
      padding: ($topbarHeightTablet) 0 2em 0;

      &.has-bar {
        padding-bottom: (0.5 + $topbarHeightTablet * 2);
      }
    }
  }

  @include desktop() {
    .AboutTop {
      height: $topbarHeightDesktop;

      .MuteButton {
        padding-right: 7px;
        svg {
          height: 18px;
          width: auto;
        }
      }
    }

    .CloseButton {
      svg {
        height: 16px;
        width: auto;
      }
    }

    .AboutContent {
      max-width: 800px;

      .columns {
        display: flex;

        .column {
          flex-grow: 1;
          flex-basis: 0;
          &:first-child {
            margin-right: 45px;
          }
          &:last-child {
            margin-left: 45px;
          }
        }
      }

      .column + .column {
        margin-top: 0;
      }
    }
  }

  .BottomBar {
    position: fixed;
    bottom: 0;
  }
}
